@import '../base/variables';

.gallery {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;

    .slider {
        display: flex;
        position: relative;
        align-items: center;
        width: 100%;

        .left,
        .right {
            display: flex;
            width: 80px;
            background: rgba(255, 255, 255, 0.7);
            padding: 30px;

            &:hover {
                cursor: pointer;
                background: rgba(255, 255, 255, 0.9);
            }
        }

        .right {
            justify-content: flex-end;
        }
    }
}

@media screen and (min-width: $break-large) {
    .gallery {
        .top {
            width: $width-large;
        }

        .slider .slide {
            min-height: 400px;
        }
    }
}

@media screen and (max-width: $break-large) and (min-width: $break-medium) {
    .gallery {
        .top {
            width: $width-medium;
        }

        .slider .slide {
            min-height: 400px;
        }
    }
}

@media screen and (max-width: $break-medium) and (min-width: $break-small) {
    .gallery {
        .top {
            width: $width-small;
        }

        .slider .slide {
            min-height: 300px;
        }
    }
}

@media screen and (max-width: $break-small) and (min-width: $break-xsmall) {
    .gallery {
        .top {
            width: $width-xsmall;
        }

        .slider .slide {
            min-height: 300px;
            max-height: 300px;
        }
    }
}

@media screen and (max-width: $break-xsmall) {
    .gallery {
        .top {
            padding-left: 20px;
            padding-right: 20px;
            width: 100%;
        }

        .slider .slide {
            min-height: 300px;
            max-height: 300px;
        }
    }
}

@media screen and (max-width: $break-medium) {
    .gallery {
        padding-bottom: 50px;
        padding-top: 50px;

        .slider {

            .left,
            .right {
                padding: 10px;
                width: 40px;
            }
        }
    }
}

.slides {
    display: flex;
    flex: 1;
    justify-content: space-around;

    .fake-slide {
        flex: 1;
        margin: 0 10px;
    }

    .slide {
        flex: 1;
        position: relative;
        margin: 0 10px;

        &:hover {
            .foreground {
                cursor: pointer;

                animation: galleryFadeOut 0.2s reverse;
                animation-fill-mode: forwards;
            }

            .background {
                animation: galleryFadeOut 0.2s ease-out;
                animation-fill-mode: forwards;
            }
        }

        .background {
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }

        .foreground {
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            padding: 20px;
            opacity: 0;

            p {
                text-transform: uppercase;
                text-align: center;
                font-size: 1.4em;
                color: #999;
                margin-bottom: 40px;
            }

            h4 {
                font-family: $secondary-font;
                font-size: 1.8em;
                text-align: center;
                color: #999;
            }

            h5 {
                // font-family: $secondary-font;
                font-size: 0.8em;
                text-align: center;
                // color: rgba(153, 153, 153, 0.6);
                color: #999;
                margin-top: 40px;
            }
        }
    }
}

@keyframes galleryFadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.2;
    }
}

i.arrow {
    border: solid #999;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;

    &.arrow-right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    &.arrow-left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }

    &.arrow-up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }

    &.arrow-down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
}

.video-list-slide {
    &:hover {
        cursor: pointer;
    }
}