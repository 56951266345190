.terms {
    width: 800px;
    flex: 1;

    h4 {
        font-size: 18px;
        margin-bottom: 80px;
        margin-top: 40px;
    }

    p {
        margin-bottom: 40px;
        line-height: 24px;
        &:last-child {
            margin-bottom: 80px;
        }
    }
}

@media screen and (min-width: $break-large) {
    .terms {
        width: $width-large;
    }
}

@media screen and (max-width: $break-large) and (min-width: $break-medium) {
    .terms {
        width: $width-medium;
    }
}

@media screen and (max-width: $break-medium) and (min-width: $break-small) {
    .terms {
        width: $width-small;
    }
}

@media screen and (max-width: $break-small) and (min-width: $break-xsmall) {
    .terms {
        width: $width-xsmall;
    }
}

@media screen and (max-width: $break-xsmall) {
    .terms {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }
}