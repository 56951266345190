@import "../base/variables";

.gallery-slider {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-bottom: 50px;

    .gallery-slider-content {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        img {
            max-width: 80vw;
            max-height: 70vh;
            object-fit: contain;
            flex: 1;
        }

        .gallery-slider-arrow {
            padding: 20px;

            &:hover {
                cursor: pointer;
            }
        }

        padding: 50px;
    }


    .gallery-slider-thumbnails {
        height: 70px;
        position: relative;
        overflow: hidden;

        .thumbnail {
            width: 60px;
            height: 60px;
            background-position: center;
            background-size: cover;
            margin: 5px;
            opacity: 1;
            transition: opacity 0.2s;

            &:hover {
                opacity: 0.4;
                cursor: pointer;
            }
        }

        .gallery-slider-thumbnails-container {
            display: flex;
            position: absolute;
            transition: transform 0.3s;
        }
    }

    .gallery-thumbnails {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .gallery-arrow-container {
            padding: 10px;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: $break-small) {
    .gallery-slider .gallery-slider-content {
        padding: 0;
    }
}