@import "../base/variables";

.full-width-video {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;

    iframe {
        position: absolute;
    }
}

.full-width-link {
    margin-top: 20px;
    &:hover a {
        color: #666;
        cursor: pointer;
    }

    a {
        padding: 20px 60px;
        border: 1px solid #999;
        color: #999;
        display: block;
    }
}

@media screen and (max-width: $break-medium) {
    .full-width-video iframe {
        min-height: 400px;
    }
}